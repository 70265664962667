import * as React from "react";
import useAPI from "hooks/API";
import useAPIWithState from "components/Form/Element/OptionElement";
import { Button, Spin, Table } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

export default function FCMAnalyzerTable() {
  const [loading, setLoading] = React.useState(false);
  const columns = [
    {
      dataIndex: "name",
      title: "Topics",
    },
    {
      dataIndex: "id",
      title: "Analyze",
      render: (id: any) => (
        <Button
          onClick={(ev) => {
            ev.stopPropagation();
            setLoading(true);
            api
              .post(`/admin/analyze/${id}`)
              .then(() => {
                if (reloadRef?.current) {
                  reloadRef?.current();
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }}
          disabled={loading}
        >
          {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />}
          {" "}
          Analyze
        </Button>
      ),
    },
  ];
  const reloadRef = React.useRef<any>();
  const api = useAPI();
  const { data } = useAPIWithState(
    async () => api.get(`/admin/firebaseAnalyzers`),
    reloadRef
  );
  const onSubmit = () => {
    if (reloadRef?.current) {
      reloadRef?.current();
    }
  };
  return (
    <>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data}
        expandable={{
          expandedRowRender: ({ id, results }: any, ...params: any) => (
            <ExpandedAnalyzerRow results={results} />
          ),
        }}
      />
    </>
  );
}

function ExpandedAnalyzerRow({ id, results }: any) {
  const resultColumns = [
    {
      dataIndex: "id",
      title: "id",
    },
    {
      dataIndex: "successCount",
      title: "Device",
    },
    {
      dataIndex: "errorCount",
      title: "error",
    },
    { dataIndex: "date", title: "date" },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      className="expanded-row"
    >
      <Table
        rowKey={"id"}
        style={{ width: "100%" }}
        columns={resultColumns}
        dataSource={results}
        expandable={{
          expandedRowRender: ({ id, errors }: any, ...params: any) => (
            <ErrorTable errors={errors} />
          ),
        }}
      />
    </div>
  );
}

const ErrorTable = ({ errors }: any) => {
  return (
    <Table
      rowKey={"id"}
      dataSource={errors}
      columns={[
        {
          dataIndex: "deviceId",
          title: "Device",
          render:(_,{userId,deviceId})=>{
            return (userId && deviceId) ? <Link to={`/users/${userId}`}>{deviceId}</Link> : (deviceId ?? userId)
          }
        },
        {
          dataIndex: "token",
          title: "Device Token",
        },
        {
          dataIndex: "error",
          title: "Error",
        },
        { dataIndex: "topic", title: "Topic" },
      ]}
    />
  );
};
